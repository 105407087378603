import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { RootState } from 'store';

import { ArrowForwardIos, Search } from '@mui/icons-material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

import CustomButton from 'components/Button';
import { FilterButtons, FilterPanelContainer, ItemListContainer, PageContainer } from 'components/GlobalStyles/styles';
import GridItem from 'components/GridItem';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import MessageBanner from 'pages/EditQuote/Components/MessageBanner';
import {
  useCreateQuoteMutation,
  useDuplicateQuoteMutation,
  useGetQuotesQuery,
} from 'services/busmanApi/quotesEndpoints';
import { QuoteSummaryProps } from 'types/WorkLogTypes';

import { QuotesGrid } from './styles';

const QuotesPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { state: snackBarMessage } = useLocation();
  const [message, setMessage] = React.useState<MessageType | null>(snackBarMessage || null);

  const { data, isLoading: isFetchingQuotes } = useGetQuotesQuery({});
  const [duplicate] = useDuplicateQuoteMutation();
  const [newQuote, { isLoading }] = useCreateQuoteMutation();
  const { clients } = useSelector((state: RootState) => state.worklogs);

  const [inputText, setInputText] = React.useState('');
  const [view, setView] = React.useState('All');
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));

  const isLoaderOpen = isFetchingQuotes || isLoading;

  if (snackBarMessage) {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '');
  }

  const viewList = ['All', ...Array.from(new Set(data?.quote_list.map((eachQuote) => eachQuote.status)))];
  const filteredData = data?.quote_list
    .filter((quote) => {
      if (inputText === '') {
        return true;
      } else {
        return quote.quote_number.toLowerCase().includes(inputText.toLowerCase());
      }
    })
    .filter((quote) => {
      if (view === viewList[0]) {
        return true;
      } else {
        return quote.status === view;
      }
    });

  const handleCreateNewQuote = async () => {
    const newQuoteResponse = await newQuote({});
    if ('error' in newQuoteResponse) {
      setMessage({ type: 'error', msg: 'Error creating new quote' });
      console.error('Error creating new quote:', newQuoteResponse.error);
    }
    if ('data' in newQuoteResponse) {
      navigate(`/editquote/${newQuoteResponse.data.id}`);
    }
  };

  const handleEditQuote = (e: any, quoteId: string) => {
    e.stopPropagation();
    navigate(`/editquote/${quoteId}`);
  };

  const handleViewQuote = (quoteId: string) => {
    navigate(`/viewquote/${quoteId}`);
  };

  const renderStatusChip = (status: string) => {
    const QUOTE_STATUS = {
      ACCEPTED: 'Accepted',
      PENDING: 'Pending',
      SUBMITTED: 'Submitted',
    };
    const chipColor = () => {
      switch (status) {
        case QUOTE_STATUS.ACCEPTED:
          return theme.palette.busmanColors.busmanGreen;
        case QUOTE_STATUS.PENDING:
          return theme.palette.busmanColors.lightGrey;
        case QUOTE_STATUS.SUBMITTED:
          return theme.palette.busmanColors.busmanPrimary;
      }
    };
    return (
      <Chip
        key={status}
        size="small"
        label={status}
        variant="outlined"
        sx={{
          color: chipColor(),
          borderColor: chipColor(),
        }}
      />
    );
  };

  const handleDuplicateQuote = async (e: any, quoteDetails: QuoteSummaryProps) => {
    e.stopPropagation();
    if (!quoteDetails.client && !quoteDetails.potential_client) {
      setMessage({ type: 'error', msg: 'Error: Client is required to duplicate a quote' });
      return;
    }

    const response = await duplicate(quoteDetails.id);

    if ('error' in response) {
      setMessage({ type: 'error', msg: 'Error creating new quote' });
      console.error('Error creating new quote:', response.error);
    }
    if ('data' in response) {
      setMessage({ msg: 'Quote duplicated successfully', type: 'success' });
      navigate(`/editquote/${response.data.id}`, { state: { type: 'success', msg: 'Quote duplicated succesfully' } });
    }
  };

  return (
    <>
      <Helmet>
        <title>BusMin</title>
        <meta name="Busmin" content="EEA-Quotes" />
      </Helmet>
      <Loader open={isLoaderOpen} />
      <SnackBar message={message} onClose={() => setMessage(null)} />
      <PageContainer>
        <FilterPanelContainer>
          <Typography gutterBottom sx={{ mb: theme.spacing(4) }} variant="h4">
            Quotes
          </Typography>
          <OutlinedInput
            endAdornment={
              <InputAdornment position="end">
                <Search />
              </InputAdornment>
            }
            onChange={(event) => setInputText(event.target.value)}
            placeholder="Search Quote Number"
            sx={{
              height: '32px',
              mb: theme.spacing(3),
            }}
            value={inputText}
          />
          <Typography sx={{ color: theme.palette.text.disabled, mb: theme.spacing(0.5) }} variant="body2">
            VIEW
          </Typography>

          <Select
            onChange={(newSelection) => {
              setView(newSelection.target.value);
            }}
            sx={{ height: '32px' }}
            value={view}>
            {viewList.map((status) => {
              return (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              );
            })}
          </Select>

          <FilterButtons sx={{ marginLeft: 'auto' }}>
            <CustomButton onClick={handleCreateNewQuote} text="New Quote" variant="contained" width="medium" />
          </FilterButtons>
          <MessageBanner message={message} isTabletScreen={isTabletScreen} />
        </FilterPanelContainer>
        <ItemListContainer>
          <Grid container display="flex" flexDirection="row">
            <GridItem title="QUOTE NUMBER" seenOrIsHeading={true} breakpoint={2} />
            <GridItem title="CLIENT" seenOrIsHeading={true} breakpoint={4} />
            {!isTabletScreen && <GridItem title="DATE CREATED" seenOrIsHeading={true} breakpoint={2} />}
            <GridItem title="STATUS" seenOrIsHeading={true} breakpoint={2} />
            <GridItem title="" seenOrIsHeading={true} breakpoint={2} />
          </Grid>
          {filteredData && filteredData.length === 0 && (
            <Typography sx={{ padding: theme.spacing(2) }} variant="h6">
              No results found...
            </Typography>
          )}
          {filteredData?.map((quote) => {
            const quotedClient = quote.client
              ? clients.find((client) => client.client_id === quote.client)?.client_name
              : quote.potential_client?.client_name;
            return (
              <QuotesGrid onClick={() => handleViewQuote(quote.id)} container key={quote.quote_number}>
                <GridItem title={quote.quote_number} seenOrIsHeading={false} breakpoint={2} />
                <GridItem title={quotedClient} seenOrIsHeading={false} breakpoint={4} />
                {!isTabletScreen && (
                  <GridItem
                    title={dayjs(quote.created_at).format('DD/MM/YYYY')}
                    seenOrIsHeading={false}
                    breakpoint={2}
                  />
                )}
                <Grid
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  item
                  justifyContent="start"
                  md={2}
                  sm={3}
                  lg={2}
                  sx={{ paddingInlineStart: theme.spacing(2) }}>
                  {renderStatusChip(quote.status)}
                </Grid>
                <Grid
                  alignItems="center"
                  display="flex"
                  flexDirection="row"
                  item
                  justifyContent="end"
                  md={2}
                  gap={2}
                  sx={{ paddingInlineEnd: theme.spacing(2), marginLeft: 'auto' }}>
                  <CustomButton
                    variant="contained_transparent"
                    width="small"
                    toolTip={'Duplicate Quote'}
                    text={<ContentCopyIcon sx={{ fontSize: '20px' }} />}
                    onClick={(e) => handleDuplicateQuote(e, quote)}
                  />
                  {quote.status !== 'Accepted' && (
                    <CustomButton
                      variant="contained_transparent"
                      width="small"
                      toolTip="Edit Quote"
                      text={<EditIcon sx={{ fontSize: '20px' }} />}
                      onClick={(e) => handleEditQuote(e, quote.id)}
                    />
                  )}
                  <ArrowForwardIos sx={{ color: theme.palette.text.disabled, fontSize: '0.8rem' }} />
                </Grid>
              </QuotesGrid>
            );
          })}
        </ItemListContainer>
      </PageContainer>
    </>
  );
};

export default QuotesPage;
