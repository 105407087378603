import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import { eeaLogo } from 'images';
import { RootState } from 'store';

import { ArrowBackIos } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {
  Box,
  Chip,
  PaletteColor,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  useMediaQuery,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { useTheme } from '@mui/material/styles';

import CustomButton from 'components/Button';
import DataNotFound from 'components/DataNotFound';
import { FilterButtons, FilterPanelContainer } from 'components/GlobalStyles/styles';
import Loader from 'components/Loader';
import SnackBar, { MessageType } from 'components/SnackBar';
import NewJob from 'features/NewJob';
import { ActivityType } from 'features/NewWorklog/types';
import MessageBanner from 'pages/EditQuote/Components/MessageBanner';
import { BackingDataContainer } from 'pages/JobDetail/BackingDocumentation/Styles/PDFstyles';
import { useGetManagerListQuery } from 'services/busmanApi';
import { useDuplicateQuoteMutation, useGetQuotesByIdQuery } from 'services/busmanApi/quotesEndpoints';
import { AllowableActivitiesType, LineHeader, LineItem, QuoteDetailProps, SummaryDetails } from 'types/QuoteTypes';
import { getHistoricalRate } from 'utils/activities';
import { COMPANY_DETAILS } from 'utils/constants';
import { convertLineHeadersToSnakeCase } from 'utils/quotes';

import {
  ContactDetails,
  Footer,
  HeaderContent,
  LogoContainer,
  QuoteDocMain,
  QuoteDocSummaryContainer,
  QuoteHeader,
  tableCell,
  TypographyContainer,
} from './styles';

const DEFAULT_VALUES = {
  id: '',
  quoteNumber: '',
  quotedDate: dayjs().format('DD/MM/YYYY'),
  taxCode: '',
  client: null,
  potentialClient: null,
  quotationPreparedBy: {
    userId: '',
    fullName: '',
    username: '',
    userRole: '',
  },
  quotesMetaData: null,
  status: 'Pending',
  createdAt: '',
  expirationDate: dayjs().add(1, 'month').format('DD/MM/YYYY'),
  updatedAt: '',
  lineHeaders: [{ id: 1, jobQuoteId: '', description: '', lineItems: [] }],
};

const ViewQuote = () => {
  const { quoteId } = useParams();
  const navigate = useNavigate();
  const theme = useTheme();
  const { state: snackBarMessage } = useLocation();
  const [formValues, setFormValues] = useState<QuoteDetailProps>(DEFAULT_VALUES);
  const [jobModalOpen, setJobModalOpen] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(true);
  const [message, setMessage] = React.useState<MessageType | null>(snackBarMessage || null);

  const [duplicate, { isLoading }] = useDuplicateQuoteMutation();
  const { activities } = useSelector((state: RootState) => state.worklogs);
  const { data: jobManagers } = useGetManagerListQuery({});
  const { data: quoteData, isFetching } = useGetQuotesByIdQuery(quoteId);
  const GST = 0.1;
  const clientObj = quoteData?.client_data ? quoteData?.client_data : null;
  const isGST = clientObj?.tax_code?.code === 'GST' || formValues?.quotesMetaData?.taxCode === 'GST';
  const isMobileScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md')) && !isMobileScreen;

  if (snackBarMessage) {
    // eslint-disable-next-line no-restricted-globals
    history.replaceState({}, '');
  }
  React.useEffect(() => {
    if (quoteData) {
      setFormValues({
        ...formValues,
        id: quoteData.id,
        status: quoteData.status,
        quoteNumber: quoteData.quote_number,
        quotedDate: dayjs(quoteData.quoted_date).format('DD-MM-YYYY'),
        taxCode: clientObj?.tax_code?.tax_id || null,
        client: quoteData?.client_data?.client_id || null,
        potentialClient: quoteData.potential_client && {
          clientName: quoteData.potential_client?.client_name || undefined,
          clientAddress: quoteData.potential_client?.client_address || undefined,
        },
        quotationPreparedBy: {
          userId: quoteData.quotation_prepared_by.user_id,
          fullName: quoteData.quotation_prepared_by.full_name,
          username: quoteData.quotation_prepared_by.username,
          userRole: quoteData.quotation_prepared_by.user_role,
        },
        quotesMetaData: {
          attn: quoteData.quotes_meta_data?.attn || '',
          scope: quoteData.quotes_meta_data?.scope || '',
          required: quoteData.quotes_meta_data?.required || '',
          deliverables: quoteData.quotes_meta_data?.deliverables || '',
          resources: quoteData.quotes_meta_data?.resources || '',
          schedule: quoteData.quotes_meta_data?.schedule || '',
          personnel: quoteData.quotes_meta_data?.personnel || '',
          taxCode: quoteData.quotes_meta_data?.tax_code || null,
          termsAndConditions: quoteData.quotes_meta_data?.terms_and_conditions || '',
        },
        createdAt: quoteData.created_at,
        expirationDate:
          dayjs(quoteData.expiration_date).format('DD-MM-YYYY') || dayjs().add(1, 'month').format('YYYY-MM-DD'),
        updatedAt: quoteData.updated_at,
        lineHeaders:
          quoteData.line_headers.length > 0
            ? [...convertLineHeadersToSnakeCase(quoteData.line_headers)]
            : [...formValues.lineHeaders],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quoteData]);

  const getUniqueActivities = () => {
    const uniqueActivityIdsSet = new Set();
    const uniqueActivities: AllowableActivitiesType[] = [];
    formValues.lineHeaders.map((lineHeader) => {
      return lineHeader.lineItems.forEach((line) => {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const activityObj: ActivityType | undefined = activities.find(
          (activity) => activity.activity_id === line.activityDetail?.activityId,
        );

        if (activityObj && !uniqueActivityIdsSet.has(activityObj.activity_id)) {
          uniqueActivityIdsSet.add(activityObj.activity_id);
          uniqueActivities.push({ activityId: activityObj.activity_id, activityName: activityObj.activity_name });
        }
      });
    });
    return uniqueActivities;
  };

  const prefillData = {
    client: { client_name: clientObj?.client_name || '', client_id: clientObj?.client_id || '' },
    allowableActivities: getUniqueActivities(),
    quoteNumber: formValues.quoteNumber,
    jobContactName: formValues.quotesMetaData?.attn || '',
    quotedHours: formValues.lineHeaders.reduce((acc, lineHeader) => {
      lineHeader.lineItems.forEach((line) => {
        acc += Number(line.units);
      });
      return acc;
    }, 0),
  };

  const calculateLineTotal = (lineItem: LineItem) => {
    if (lineItem.lineType === 'Inventory') return lineItem.units * Number(lineItem.customCost) - lineItem.discount;
    if (lineItem.lineType === 'Activity') {
      const lineRate =
        lineItem.activityDetail?.activityId &&
        getHistoricalRate(
          activities,
          lineItem.activityDetail.activityId,
          dayjs(quoteData?.quoted_date).format('YYYY-MM-DD'),
        );
      return lineItem.units * Number(lineRate) - lineItem.discount;
    }
    return 0;
  };

  const calculateLineHeaderTotal = (lineHeader: LineHeader) => {
    let total = 0;
    lineHeader.lineItems.forEach((line) => {
      total += calculateLineTotal(line);
    });

    return total.toFixed(2);
  };

  const calculateTotalDiscount = (lineHeader: LineHeader) => {
    let totalDiscount = 0;

    lineHeader.lineItems.forEach((line) => {
      totalDiscount += Number(line.discount);
    });

    return totalDiscount;
  };

  const calculateTotal = (lineHeaders: LineHeader[]) => {
    let total = 0;

    lineHeaders.forEach((lineHeader) => {
      lineHeader.lineItems.forEach((line) => {
        total += calculateLineTotal(line);
      });
    });

    return total;
  };

  const handleCloseJobModal = () => {
    setJobModalOpen(!jobModalOpen);
  };

  const handleOpenSnackbar = (displayMessage: MessageType) => {
    setMessage(displayMessage);
  };

  const openPdf = async () => {
    window.print();
  };

  const RenderHeader = () => {
    return (
      <>
        <QuoteHeader>
          <HeaderContent>
            <LogoContainer>
              <img style={{ objectFit: 'contain', width: '100%' }} src={eeaLogo} alt="Element Logo" />
            </LogoContainer>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(1),
              }}>
              <Typography sx={{ fontWeight: 'bold' }} variant="h4">
                Quote
              </Typography>
              <Typography variant="subtitle1">{COMPANY_DETAILS.company_name}</Typography>
            </Box>
          </HeaderContent>
          <ContactDetails>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(1),
              }}>
              <Typography variant="body1">{COMPANY_DETAILS.company_abn}</Typography>
              <Typography variant="body1">{COMPANY_DETAILS.company_phone}</Typography>
              <Typography variant="body1">{COMPANY_DETAILS.company_email}</Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(1),
              }}>
              <Typography variant="body2">ABN</Typography>
              <Typography variant="body2">PHONE</Typography>
              <Typography variant="body2">EMAIL</Typography>
            </Box>
          </ContactDetails>
          <HeaderContent>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: '25%' }}>
              <Typography variant="body2">ATTN</Typography>
              <Typography sx={{ marginBottom: theme.spacing(2) }} variant="body1">
                {formValues.quotesMetaData?.attn}
              </Typography>
              <Typography variant="body2">CLIENT</Typography>
              <Typography variant="body1">
                {clientObj?.client_name || formValues.potentialClient?.clientName}
              </Typography>
              <Typography variant="body1">
                {clientObj?.address?.street || formValues.potentialClient?.clientAddress}
              </Typography>
              {formValues.client && (
                <Typography variant="body1">
                  {clientObj?.address?.city} {clientObj?.address?.postal_code} {clientObj?.address?.state}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-end',
                gap: theme.spacing(2),
              }}>
              <TypographyContainer>
                <Typography variant="body2">QUOTE NUMBER</Typography>
                <Typography variant="body1">{formValues.quoteNumber}</Typography>
              </TypographyContainer>
              <TypographyContainer>
                <Typography variant="body2">QUOTE DATE</Typography>
                <Typography variant="body1">{formValues.quotedDate}</Typography>
              </TypographyContainer>
              <TypographyContainer>
                <Typography variant="body2">QUOTE EXPIRATION</Typography>
                <Typography variant="body1">
                  {formValues.expirationDate !== 'Invalid Date' ? formValues.expirationDate : ''}
                </Typography>
              </TypographyContainer>
              <TypographyContainer>
                <Typography variant="body2">TAX CODE</Typography>
                <Typography variant="body1">
                  {clientObj?.tax_code?.code || formValues?.quotesMetaData?.taxCode}
                </Typography>
              </TypographyContainer>
            </Box>
          </HeaderContent>
        </QuoteHeader>
      </>
    );
  };

  const RenderTable = () => {
    const tableFields = ['UNITS', 'DESCRIPTION', 'RATE/COST', 'DISCOUNT', 'LINE TOTAL'];

    return (
      <Box
        sx={{
          paddingLeft: theme.spacing(4),
          paddingRight: theme.spacing(4),
          paddingTop: theme.spacing(2),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(2),
        }}>
        <TableContainer>
          <Table>
            <colgroup>
              <col width="10%" />
              <col width="35%" />
              <col width="25%" />
              <col width="12.5%" />
              <col width="12.5%" />
              <col width="5%" />
            </colgroup>
            <TableHead>
              <TableRow>
                {tableFields.map((field) => {
                  return field === 'LINE TOTAL' ? (
                    <TableCell
                      sx={{ ...tableCell, paddingBottom: theme.spacing(1) }}
                      align="right"
                      key={field}
                      colSpan={2}>
                      {field}
                    </TableCell>
                  ) : (
                    <TableCell sx={{ ...tableCell, paddingBottom: theme.spacing(1) }} key={field}>
                      {field}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            {formValues.lineHeaders.map((lineheader, idx) => {
              const discountTotal = calculateTotalDiscount(lineheader);
              return (
                <TableBody
                  key={idx}
                  sx={{
                    border: `1px solid ${theme.palette.background.default}`,
                    padding: theme.spacing(4),
                    pageBreakInside: 'avoid',
                  }}>
                  <TableRow>
                    <TableCell sx={tableCell}>
                      <IconButton
                        sx={{
                          '@media print': {
                            display: 'none',
                          },
                        }}
                        aria-label="expand row"
                        size="small"
                        onClick={() => setIsOpen(!isOpen)}>
                        {!isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                      </IconButton>
                    </TableCell>
                    <TableCell colSpan={2} sx={{ ...tableCell, width: '40%', fontWeight: 'bold', columnSpan: 2 }}>
                      <TextField
                        value={lineheader.description}
                        InputProps={{
                          readOnly: true,
                          multiline: true,
                          fullWidth: true,
                        }}
                        sx={{
                          width: '100%',
                          borderRadius: '2px',
                          '& .MuiOutlinedInput-root': {
                            fontSize: '10px',
                            fontWeight: 'bold',
                            padding: 0,
                            border: 'none !important',
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important',
                            },
                          },
                        }}
                      />
                    </TableCell>
                    <TableCell sx={tableCell}>
                      <Typography sx={{ ...tableCell, fontWeight: 'bold' }}>
                        {discountTotal > 0 ? `$${discountTotal.toFixed(2)}` : '--'}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={2} sx={{ ...tableCell, textAlign: 'right', fontWeight: 'bold' }}>
                      ${calculateLineHeaderTotal(lineheader)}
                    </TableCell>
                  </TableRow>
                  {isOpen &&
                    lineheader.lineItems.map((line) => {
                      const lineRate = getHistoricalRate(
                        activities,
                        line.activityDetail?.activityId || '',
                        dayjs(quoteData?.quoted_date).format('YYYY-MM-DD'),
                      );
                      return (
                        <TableRow key={line.description}>
                          <TableCell sx={tableCell}>{line.units}</TableCell>
                          <TableCell sx={tableCell}>
                            <TextField
                              value={line.description}
                              InputProps={{
                                readOnly: true,
                                multiline: true,
                                fullWidth: true,
                              }}
                              sx={{
                                width: '100%',
                                borderRadius: '2px',
                                '& .MuiOutlinedInput-root': {
                                  fontSize: '10px',
                                  padding: 0,
                                  border: 'none !important',
                                  '&:hover .MuiOutlinedInput-notchedOutline': {
                                    border: 'none !important',
                                  },
                                  '& .MuiOutlinedInput-notchedOutline': {
                                    border: 'none !important',
                                  },
                                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                    border: 'none !important',
                                  },
                                },
                              }}
                            />
                          </TableCell>
                          {line.lineType === 'Activity' ? (
                            <TableCell sx={tableCell}>
                              <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0 }}>
                                ${lineRate}
                              </Typography>
                              <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0, fontStyle: 'italic' }}>
                                {line?.activityDetail?.activityName}
                              </Typography>
                            </TableCell>
                          ) : (
                            <TableCell sx={tableCell}>
                              <Typography sx={{ ...tableCell, paddingTop: 0, paddingBottom: 0 }}>
                                ${line.customCost}
                              </Typography>
                            </TableCell>
                          )}
                          <TableCell sx={tableCell}>${line.discount}</TableCell>
                          <TableCell colSpan={2} sx={{ ...tableCell, textAlign: 'right' }}>
                            ${calculateLineTotal(line).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              );
            })}
          </Table>
        </TableContainer>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
          <CustomButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
            text={isOpen ? 'Collapse All' : 'Expand All'}
            variant="contained_grey"
            width="medium"
          />
        </Box>
      </Box>
    );
  };

  const RenderFooter = (docType: string) => {
    const quoteSubTotal = calculateTotal(formValues.lineHeaders);
    const quoteTotal = isGST ? (quoteSubTotal * (1 + GST)).toFixed(2) : quoteSubTotal.toFixed(2);
    const quoteTax = (quoteSubTotal * GST).toFixed(2);
    return (
      <Box
        sx={{
          marginTop: 'auto',
        }}>
        {docType === 'detailed' && (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              gap: theme.spacing(2),
              marginTop: 'auto',
              padding: theme.spacing(4),
              pageBreakInside: 'avoid',
            }}>
            <TypographyContainer>
              <Typography variant="body2">SUBTOTAL</Typography>
              <Typography variant="body1">${quoteSubTotal.toFixed(2)}</Typography>
            </TypographyContainer>
            {isGST && (
              <TypographyContainer>
                <Typography variant="body2">GST</Typography>
                <Typography variant="body1">${quoteTax}</Typography>
              </TypographyContainer>
            )}
            <TypographyContainer>
              <Typography variant="body2">TOTAL</Typography>
              <Typography variant="body1">${quoteTotal}</Typography>
            </TypographyContainer>
          </Box>
        )}
        <Footer>
          <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: theme.spacing(2) }}>
            <Typography variant="body2">QUOTATION PREPARED BY</Typography>
            <Typography variant="body1">{formValues.quotationPreparedBy.fullName}</Typography>
          </Box>
          <Box>
            <Typography>{formValues.quotesMetaData?.termsAndConditions}</Typography>
          </Box>
        </Footer>
      </Box>
    );
  };

  const RenderSummary = () => {
    const summaryKeys = ['scope', 'required', 'deliverables', 'personnel', 'resources', 'schedule'];
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(2),
            padding: theme.spacing(4),
          }}>
          {summaryKeys.map((key: string) => {
            return (
              <>
                <Box
                  key={key}
                  sx={{ display: 'flex', flexDirection: 'row', gap: theme.spacing(2), verticalAlign: 'top' }}>
                  <Box sx={{ width: '15%' }}>
                    <Typography variant="body2">{key.toUpperCase()}</Typography>
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    {formValues.quotesMetaData && (
                      <TextField
                        value={formValues.quotesMetaData[key as keyof SummaryDetails]}
                        InputProps={{
                          readOnly: true,
                          multiline: true,
                          fullWidth: true,
                        }}
                        sx={{
                          width: '100%',
                          borderRadius: '2px',
                          '& .MuiOutlinedInput-root': {
                            border: 'none !important',
                            padding: 0,
                            '&:hover .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important',
                            },
                            '& .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important',
                            },
                            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                              border: 'none !important',
                            },
                          },
                        }}
                      />
                    )}
                  </Box>
                </Box>
              </>
            );
          })}
        </Box>
      </>
    );
  };

  const handleDuplicateQuote = async () => {
    if (!formValues.client && !formValues.potentialClient) {
      setMessage({ type: 'error', msg: 'Error: Client is required to duplicate a quote' });
      return;
    }

    const response = await duplicate(formValues.id);

    if ('error' in response) {
      setMessage({ type: 'error', msg: 'Error Duplicating Quote' });
      console.error('Error creating new quote:', response.error);
    }
    if ('data' in response) {
      setMessage({ msg: 'Quote duplicated successfully', type: 'success' });
      navigate(`/editquote/${response.data.id}`, { state: { type: 'success', msg: 'Quote duplicated succesfully' } });
    }
  };

  const FilterPanel = () => {
    const renderStatusChip = (status: string) => {
      const QUOTE_STATUS = {
        ACCEPTED: 'Accepted',
        PENDING: 'Pending',
        SUBMITTED: 'Submitted',
      };
      const chipColor = () => {
        switch (status) {
          case QUOTE_STATUS.ACCEPTED:
            return theme.palette.busmanColors.busmanGreen as PaletteColor['main'];
          case QUOTE_STATUS.PENDING:
            return theme.palette.busmanColors.lightGrey as PaletteColor['main'];
          case QUOTE_STATUS.SUBMITTED:
            return theme.palette.busmanColors.busmanPrimary as PaletteColor['main'];
        }
      };
      return (
        <Chip
          key={status}
          size="small"
          label={status}
          variant="outlined"
          sx={{
            mt: theme.spacing(3),
            mb: theme.spacing(1),
            width: 'fit-content',
            color: chipColor(),
            borderColor: chipColor(),
          }}
        />
      );
    };
    return (
      <FilterPanelContainer>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            borderRadius: '2px',
            padding: theme.spacing(1),
            height: 'fit-content',
          }}>
          <Box display="flex" alignItems="center" sx={{ gap: theme.spacing(1) }}>
            <ArrowBackIos
              sx={{
                color: theme.palette.text.disabled,
                fontSize: '0.8rem',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/quotes');
              }}
            />
            <Typography variant="h4">{formValues.quoteNumber}</Typography>
          </Box>
        </Box>
        {renderStatusChip(formValues.status)}
        <FilterButtons>
          {formValues.status === 'Accepted' ? (
            <CustomButton onClick={handleCloseJobModal} text="Create Job" width="100%" variant="contained_purple" />
          ) : (
            <CustomButton
              onClick={() => {
                navigate(`/editquote/${formValues.id}`);
              }}
              text="Edit Quote"
              width="100%"
              variant="contained_grey"
            />
          )}
          <CustomButton
            onClick={handleDuplicateQuote}
            text="Duplicate Quote"
            width="100%"
            variant="contained_grey"
            disabled={isFetching}
          />
        </FilterButtons>
        <FilterButtons
          sx={{
            mt: theme.spacing(2),
          }}>
          <CustomButton
            onClick={() => {
              openPdf();
            }}
            text="Create PDF"
            width="100%"
            variant="contained"
          />
        </FilterButtons>
        <MessageBanner message={message} isTabletScreen={isTabletScreen} />
      </FilterPanelContainer>
    );
  };

  return (
    <>
      <Loader open={isFetching} />
      {quoteData && !isFetching && (
        <Box sx={BackingDataContainer}>
          {!jobModalOpen && <FilterPanel />}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              gap: theme.spacing(2),
              height: 'fit-content',
              marginBottom: theme.spacing(4),
              '@media print': {
                gap: 0,
              },
            }}>
            <QuoteDocMain>
              {RenderHeader()}
              {RenderTable()}
              {RenderFooter('detailed')}
            </QuoteDocMain>
            <QuoteDocSummaryContainer>
              {RenderHeader()}
              {RenderSummary()}
              {RenderFooter('summary')}
            </QuoteDocSummaryContainer>
          </Box>
          {jobModalOpen && (
            <NewJob
              close={handleCloseJobModal}
              showMessage={handleOpenSnackbar}
              jobManagers={jobManagers || []}
              preFillData={prefillData}
            />
          )}
        </Box>
      )}
      {!quoteData && !isFetching && <DataNotFound errorMessage="Quote Not Found" url="quotes" />}
      <SnackBar message={message} onClose={() => setMessage(null)} />
    </>
  );
};

export default ViewQuote;
